import { ACT } from '@/utils/constants'
import { BaseParams } from './BaseParams'

export class MultipleBetDeskInfo extends BaseParams {
    sessionID?: string
    desk?: string // 1,2,3,4,5,,6,,6,7
    username?: string
    /**
     *
     */
    constructor(uid: string, desk: string, sid: string) {
        super(ACT.MULTIPLE_BET_DESK_INFO)
        this.sessionID = sid
        this.desk = desk
        this.username = uid
    }
}
