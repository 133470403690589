<template>
    <div
        class="multi-prediction-root"
        :data-label="label"
        :data-color="color"
        :class="['red', { blue: color === 1 }]"
        @click="clicked"
    >
        <div class="multi-prediction-content">
            <div class="icon">
                <span
                    class="icon-circlestroke"
                    :class="bigeyeColor"
                    v-if="hasBigEye"
                >
                </span>
            </div>
            <div class="icon">
                <span
                    class="icon-circlefill"
                    :class="smallColor"
                    v-if="hasSmall"
                ></span>
            </div>
            <div class="icon">
                <span
                    class="icon-vertical"
                    :class="cockRoachColor"
                    v-if="hasCockRoach"
                ></span>
            </div>
        </div>
    </div>
</template>

<script>
import { PredictionSumaryMixin } from '@/components/desk/prediction/prediction-sumary-mixin'
import { Options } from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'

@Options({})
export default class MultiPrediction extends Mixins(PredictionSumaryMixin) {}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.multi-prediction-root {
    font-size: 1rem;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    &[data-label]::before {
        content: attr(data-label);
        font-size: 1.5em;
        letter-spacing: 0.3em;
        writing-mode: vertical-lr;
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
    }

    &[data-color='2']::before {
        color: #fe2709;
    }
    &[data-color='1']::before {
        color: #3cadff;
    }
    &.red {
        background-color: rgba(251, 60, 60, 0.4);
    }
    &.blue {
        background-color: rgba(53, 79, 251, 0.4);
    }
    .multi-prediction-content {
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .icon {
            height: calc(100% / 3);
            width: 100%;
            font-size: 1.3em;
            display: flex;
            justify-content: center;
            align-items: center;
            .red {
                color: #fe2709;
            }
            .blue {
                color: #3cadff;
            }
        }
    }
}
</style>