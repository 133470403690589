
import { DeskData } from '@/datamodel/deskdata'
import { ResponseData } from '@/datamodel/response-data'
import { MultipleBetDeskInfo } from '@/datamodel/urlparams/MultipleBetDeskInfo'
import { Device } from '@/store/types'
import { Options, Vue } from 'vue-class-component'
import { Action, Getter } from 'vuex-class'
import HeaderView from '@/components/header/index.vue'
import { VTextMarquee } from 'vue-text-marquee'
import { Screenfull } from 'screenfull'
import screenfull from 'screenfull'
import { Watch } from 'vue-property-decorator'
import PcSwitchTable from '@/components/desk/switch-table/d-switch-table.vue'
import CustomMoneyView from '@/components/dialogs/custom-money-dialog-view.vue'
import LimitDialogView from '@/components/dialogs/limit-dialog-view.vue'
import SettingView from '@/components/dialogs/setting-dialog-view.vue'
import ChangePasswordView from '@/components/dialogs/change-pass-view.vue'
import BetHistory from '@/components/dialogs/history-view.vue'
import { addDays, to24HoursFormat } from '@/utils'
import CryptoJS from 'crypto-js'
import MultiViewCenterListItem from '@/components/multi-view/center-list-item.vue'
import MultiViewSideListItem from '@/components/multi-view/side-list-item.vue'
import { BaseParams } from '@/datamodel/urlparams/BaseParams'
import { ACT } from '@/utils/constants'
import { getDeskInfo, getVideoInformation } from '@/api/desk'
import axios, { CancelTokenSource } from 'axios'

@Options({
    components: {
        HeaderView,
        'marquee-text': VTextMarquee,
        CustomMoneyView,
        PcSwitchTable,
        LimitDialogView,
        SettingView,
        ChangePasswordView,
        BetHistory,
        MultiViewCenterListItem,
        MultiViewSideListItem
    }
})
export default class MultiViewLoby extends Vue {
    showLimitDialog = false
    showHistory = false
    showPasswordForm = false
    showSettings = false
    showCustumChip = false
    showSwicthTable = false
    activeDragIndex = -1

    videoIsLoading = true

    nodePlayer!: any

    videoPlayerCollection: Array<any> = new Array<any>()

    currentDeskNo = -1
    selectedTables = new Array<number>()

    desks = new Array<DeskData>()
    currentDesk: DeskData | undefined = undefined

    dateTimeStr = to24HoursFormat(new Date())
    dateTimeInterval: number | boolean = false

    hasPendingRequest = false

    currentTime = -1

    deskTimer: number | boolean = false
    serviceTimer: number | boolean = false
    countDownTimer: number | boolean = false

    isNoticeView = true
    noticeIsPaused = false

    videos: string[] = []
    videoRequestFailure = 0
    videoRequestTimeOut: boolean | number | any = false
    videoAxiosSource?: CancelTokenSource

    @Getter('isFullScreen') isFullScreen!: boolean
    @Getter('chips') chips!: Array<number>
    @Getter('customChipValue') customChipValue!: number
    @Getter('currentChipIndex') chipIndex!: number
    @Getter('device') device!: Device
    @Getter('multiTablesDesk') multiTablesDesk!: Array<number>
    @Getter('userId') userId!: string
    @Getter('sessionId') sessionId!: string
    @Getter('deskNotice') deskNotice!: string
    @Getter('deskInfoKey') deskInfoKey!: number
    @Getter('betCoin') betCoin!: number

    @Action('showLoading') showLoading!: () => void
    @Action('hideLoading') hideLoading!: () => void
    @Action('destroySession') destroySession!: () => void
    @Action('getMultiViewTables') getMultiViewTables!: (
        model: any
    ) => Promise<ResponseData>
    @Action('setFullScreen') setFullScreen!: (isFull: boolean) => void
    @Action('setCurrentChipIndex') setCurrentChipIndex!: (
        indexPosition: number
    ) => void
    @Action('setBetCoin') setBetCoin!: (userPoint: number) => void
    @Action('setCurrentDesk') enterGameLoby!: (
        model: DeskData | undefined
    ) => void

    //#region Vue Methods
    beforeMount(): void {
        if (this.device.display === 1) {
            this.$router.push({ name: 'loby' })
            return
        }

        for (let i = 0; i < 6; i++) {
            const deskNo = this.multiTablesDesk[i] || -1

            if (deskNo !== -1) {
                this.selectedTables.push(deskNo)
                if (i === 0) this.currentDeskNo = deskNo
            }
        }
    }

    mounted(): void {
        // this.currentDeskNo = this.currentDesk?.deskId || 0
        this.fetchData()
        this.dateTimeInterval = setInterval(() => {
            this.dateTimeStr = to24HoursFormat(new Date())
        }, 1000)
    }

    beforeUnmount(): void {
        this.endCounDownService()
        this.endService()
        this.stopRequestingVideoInfos()
        this.disposeVideoPlayer()
        if (typeof this.dateTimeInterval === 'number') {
            clearInterval(this.dateTimeInterval)
            this.dateTimeInterval = false
        }
    }

    //#endregion

    stopRequestingVideoInfos(): void {
        if (typeof this.videoAxiosSource !== 'undefined') {
            this.videoAxiosSource.cancel()
            this.videoAxiosSource = axios.CancelToken.source()
        }

        if (typeof this.videoRequestTimeOut === 'number') {
            clearTimeout(this.videoRequestTimeOut)
            this.videoRequestTimeOut = false
        }
    }

    OnShowSwitchTable(): void {
        this.showSwicthTable = !this.showSwicthTable
    }

    getVideoInfos() {
        this.videos = []
        this.disposeVideoPlayer()

        const _params = {
            ...new BaseParams(ACT.VIDEO_INFOS),
            sessionID: this.sessionId,
            desk: this.currentDeskNo || -1,
            username: this.userId
        }

        // set request cancel token
        if (typeof this.videoAxiosSource === 'undefined')
            this.videoAxiosSource = axios.CancelToken.source()

        getVideoInformation(_params, this.videoAxiosSource)
            .then(response => {
                if (response.data !== '') {
                    this.videos = response.data.split('#')

                    // initPlayer()
                    this.initVideoPlayer()
                }
            })
            .catch(e => {
                if (!axios.isCancel(e)) {
                    // request video info again
                    this.videoRequestFailure += 1

                    if (this.videoRequestFailure > 3) {
                        this.stopRequestingVideoInfos()
                        this.endService()
                        this.destroySession()
                        this.$router.push({ name: 'login' })
                        return
                    }

                    this.videoRequestTimeOut = setTimeout(() => {
                        this.getVideoInfos()
                    }, 2000)
                }
            })
    }
    fetchData(): void {
        this.hasPendingRequest = true
        this.showLoading()

        this.getMultiViewTables(this.getServiceParams())
            .then((response: ResponseData) => {
                this.hideLoading()
                if (response.error) {
                    if (response.error == '2') {
                        // session is expired logut user and redirect it to login
                        this.endService()
                        this.destroySession()
                        this.$router.push({ name: 'login' })
                    } else {
                        this.hasPendingRequest = false
                        this.startService()
                    }
                } else {
                    if (response.data) {
                        this.desks = DeskData.ParseList(response.data)

                        if (this.desks.length > 1) {
                            const desk = this.desks[0]
                            this.currentDeskNo = desk.deskId || -1

                            if (desk.balance !== this.betCoin) {
                                this.setBetCoin(desk.balance)
                            }

                            this.setCurrentDesk(desk)
                        }
                    }
                    this.hasPendingRequest = false
                    this.startService()
                }
            })
            .catch(e => {
                if (!axios.isCancel(e)) {
                    setTimeout(() => {
                        this.fetchData()
                    }, 2000)
                }
            })
    }

    startService(): void {
        // end the service if its running
        this.endService()
        // create a service that get data every 3 seconds
        this.serviceTimer = setInterval(() => {
            if (!this.hasPendingRequest) {
                this.hasPendingRequest = true

                this.getMultiViewTables(this.getServiceParams())
                    .then((response: ResponseData) => {
                        if (response.error) {
                            if (response.error == '2') {
                                // session is expired logut user and redirect it to login
                                this.endService()
                                this.destroySession()
                                this.$router.push({ name: 'login' })
                            } else {
                                this.hasPendingRequest = false
                            }
                        } else {
                            if (response.data) {
                                this.desks = DeskData.ParseList(response.data)

                                for (let i = 0; i < this.desks.length; i++) {
                                    const desk = this.desks[i]
                                    if (desk.deskId === this.currentDeskNo) {
                                        this.setCurrentDesk(desk)
                                        break
                                    }
                                }
                                if (this.desks.length > 0) {
                                    const desk = this.desks[0]
                                    if (desk.balance !== this.betCoin) {
                                        this.setBetCoin(desk.balance)
                                    }
                                }
                            }
                            this.hasPendingRequest = false
                        }
                    })
                    .catch(() => {
                        this.hasPendingRequest = false
                    })
            }
        }, 1000)
    }

    endService(): void {
        if (typeof this.serviceTimer === 'number') {
            clearInterval(this.serviceTimer)
            this.serviceTimer = false
        }
    }

    startCounDownService(): void {
        this.endCounDownService()
        this.countDownTimer = setInterval(() => {
            if (this.desks && this.desks.length > 0) {
                this.desks.forEach((desk: DeskData) => {
                    if (desk.currentTime !== -1) {
                        desk.currentTime -= 1
                    }
                })
            }
        }, 1000)
    }

    endCounDownService(): void {
        if (typeof this.countDownTimer === 'number') {
            clearInterval(this.countDownTimer)
            this.countDownTimer = false
        }
    }

    getServiceParams(): MultipleBetDeskInfo {
        let tables = '' // fromat 1,2,3,4,5
        this.multiTablesDesk.forEach((tableNo: number) => {
            tables += `,${tableNo}`

            this.desks.forEach((desk: DeskData) => {
                if (tableNo === desk.deskId) {
                    if (desk.gameStatus === 4) {
                        tables += `.${desk.gameId}`
                    }
                }
            })
        })

        tables = tables.substring(1)
        return new MultipleBetDeskInfo(this.userId, tables, this.sessionId)
    }

    setCurrentDesk(desk: DeskData): void {
        if (!desk) return

        // check old and new desk here
        // before setting a new timer monitor

        if (desk.deskId !== this.currentDesk?.deskId) {
            // terminate timer here
            if (typeof this.deskTimer === 'number') {
                clearInterval(this.deskTimer)
                this.deskTimer = false
                this.currentTime = -1
            } else {
                this.currentTime = -1
            }
        }

        this.currentDesk = desk

        if (
            this.currentDesk.currentTime > -1 &&
            typeof this.deskTimer !== 'number'
        ) {
            this.currentTime = this.currentDesk.currentTime
            this.deskTimer = setInterval(() => {
                if (this.currentTime !== -1) {
                    this.currentTime -= 1
                } else {
                    if (typeof this.deskTimer === 'number') {
                        clearInterval(this.deskTimer)
                        this.deskTimer = false
                        this.currentTime = -1
                    }
                }
            }, 1000)
        } else if (
            this.currentDesk.currentTime === -1 &&
            typeof this.deskTimer === 'number'
        ) {
            // terminate the timer
            clearInterval(this.deskTimer)
            this.deskTimer = false
            this.currentTime = -1
        }
    }

    OnCLickToggleFullScreen(): void {
        this.setFullScreen(!this.isFullScreen)
        if (!this.isFullScreen && screenfull.isEnabled) {
            const sc = screenfull as Screenfull
            sc.exit()
        }
    }

    getChipImageWebp(amount: number): string {
        return require(`@/assets/imgs/chips${amount}.webp`)
    }

    getChipImageWebpPng(amount: number): string {
        return require(`@/assets/imgs/chips${amount}.png`)
    }
    getCustomChipValue(): unknown {
        return this.customChipValue <= 0 ? '自定义' : this.customChipValue
    }

    chipClicked(index: number): void {
        if (index === this.chipIndex && this.chipIndex !== -1) {
            this.setCurrentChipIndex(-1)
            // this.chip = 0
            return
        }

        this.setCurrentChipIndex(index)
        // this.chip = amount
    }

    showChipDialog(isTrue: boolean): void {
        if (isTrue) {
            this.showCustumChip = true
        } else {
            if (this.customChipValue > 0) {
                if (this.chipIndex === 5) {
                    // this.chip = 0
                    this.setCurrentChipIndex(-1)
                } else {
                    this.setCurrentChipIndex(5)
                    // this.chip = this.customChipValue
                }
            } else {
                this.showCustumChip = true
            }
        }
    }

    OnClickCustumerService(): void {
        window.open(process.env.VUE_APP_CUSTUMER_SERVICE, '_blank')
    }

    OnClickRules(): void {
        window.open(
            'http://kkw6888.com/resource/rules/rule_home.html',
            '_blank'
        )
    }

    onSwitchTable(desk: DeskData): void {
        this.showSwicthTable = false

        this.enterGameLoby(desk)
        this.$router.push({ name: 'game' })
        // this.endService()
        // // reset betting
    }

    OnSwitchDeskFocus(deskNo: number): void {
        if (this.currentDeskNo !== deskNo) {
            this.stopRequestingVideoInfos()
            this.currentDeskNo = deskNo
        }
    }

    //#region Video Player
    initVideoPlayer(): void {
        this.videoIsLoading = true
        this.disposeVideoPlayer()

        // const videoPaths = [
        //     process.env.VIDEO1_PATH,
        //     process.env.VIDEO2_PATH,
        //     process.env.VIDEO3_PATH,
        //     process.env.VIDEO4_PATH
        // ]
        let isVideoConnected = false

        this.videos.forEach(vPath => {
            // eslint-disable-next-line no-undef
            const _player = new NodePlayer()
            _player.setVolume(0)
            _player.setView('videoplayer2')
            _player.setBufferTime(1000)
            _player.on('start', () => {
                if (!isVideoConnected) {
                    isVideoConnected = true
                    this.nodePlayer = _player
                    this.nodePlayer.clearView()
                    this.nodePlayer.setView('videoplayer1')
                    this.nodePlayer.on(
                        'buffer',
                        (evt: 'empty' | 'buffering' | 'full') => {
                            if (evt === 'empty' || evt === 'buffering') {
                                this.videoIsLoading = true
                            } else if (evt === 'full') {
                                this.videoIsLoading = false
                            }
                        }
                    )
                    this.nodePlayer.on('error', (e: any) => {
                        this.videoIsLoading = true
                        setTimeout(() => {
                            this.nodePlayer.clearView()
                            this.nodePlayer.stop()
                            this.initVideoPlayer()
                        }, 1000)
                    })
                } else {
                    _player.clearView()
                    _player.stop()
                }
            })
            _player.on('error', () => {
                //
            })
            this.videoPlayerCollection.push()
            _player.start(vPath)
        })
    }

    disposeVideoPlayer(): void {
        if (this.nodePlayer) {
            this.nodePlayer.stop()
            this.nodePlayer.clearView()
            this.nodePlayer = undefined
            this.videoIsLoading = true
        }

        this.videoPlayerCollection.forEach(_player => {
            if (_player) {
                _player.clearView()
                _player.stop()
            }
        })

        this.videoPlayerCollection = []
    }

    //#endregion

    //#region  Drag Events

    handleDragStart(_deskData: DeskData, e: DragEvent): void {
        const _deskId = _deskData.deskId as number
        e.dataTransfer?.setData('deskId', _deskId.toString())
    }

    handleDrop(_deskData: DeskData, e: DragEvent): void {
        const toId = e.dataTransfer?.getData('deskId')

        const fromId = this.selectedTables.indexOf(_deskData.deskId as number)

        this.selectedTables[fromId] = parseInt(toId as string)
    }

    handleDragLeave(e: DragEvent): void {
        const id = (e.relatedTarget as HTMLElement).id
        if (id === 'box1' || id === 'box2') {
            this.activeDragIndex = -1
        }
    }

    //#endregion

    @Watch('customChipValue', { immediate: false })
    WatchCustumChipChange(value: number): void {
        if (value > 0) this.setCurrentChipIndex(5)
    }

    @Watch('currentDeskNo', { immediate: false })
    watchCurrentDesk(value: number, old: number): void {
        if (value > 0) {
            this.getVideoInfos()
        }
    }

    get timerColor(): string {
        if (this.currentTime > 20) {
            return 'primary'
        } else if (this.currentTime <= 20 && this.currentTime > 10) {
            return 'warning'
        } else {
            return 'danger'
        }
    }

    get leftListData(): Array<DeskData> {
        const _listData = new Array<DeskData>()
        this.selectedTables.forEach((deskNo: number, index: number) => {
            if (index > 2) return _listData
            for (let i = 0; i < this.desks.length; i++) {
                if (deskNo === this.desks[i].deskId)
                    _listData.push(this.desks[i])
            }
        })
        return _listData
    }

    get rightListData(): Array<DeskData> {
        const _listData = new Array<DeskData>()

        this.selectedTables.forEach((deskNo: number, index: number) => {
            if (index > 2) {
                for (let i = 0; i < this.desks.length; i++) {
                    if (deskNo === this.desks[i].deskId)
                        _listData.push(this.desks[i])
                }
            }
        })

        return _listData
    }

    get centerListData(): Array<DeskData> {
        const _listData = new Array<DeskData>()
        this.desks.forEach((desk: DeskData) => {
            let isListed = false
            for (let index = 0; index < 6; index++) {
                const tableNo = this.selectedTables[index]
                if (tableNo === desk.deskId) {
                    isListed = true
                    break
                }
            }
            if (!isListed) _listData.push(desk)
        })

        return _listData
    }

    get chip(): number {
        if (this.chipIndex === 5) return this.customChipValue

        if (this.chipIndex === -1) return 0

        const amt = this.chips[this.chipIndex]
        if (amt) return amt
        return 0
    }

    get title(): string {
        let currentDeskData: DeskData | undefined = undefined

        for (let i = 0; i < this.desks.length; i++) {
            const desk = this.desks[i]
            if (desk.deskId === this.currentDeskNo) {
                currentDeskData = desk
                break
            }
        }

        if (!currentDeskData) return ''

        const { gameType, deskId } = currentDeskData as DeskData

        if (gameType === 1) {
            return `百家乐 ${deskId} 号桌`
        } else {
            return `龙虎 ${deskId} 号桌`
        }
    }
}
