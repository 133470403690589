
import { Options } from 'vue-class-component'
import RoadMapView from '@/components/roadmap/index.vue'
import { LobyListItemMixin } from '@/components/loby/list-item-mixin'
import { Mixins } from 'vue-property-decorator'

@Options({
    name: 'list-box-item',
    components: {
        RoadMapView
    }
})
export default class MultiViewCenterListItem extends Mixins(
    LobyListItemMixin
) {}
