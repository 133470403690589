
import { DeskData } from '@/datamodel/deskdata'
import { GameResult } from '@/datamodel/game-result'
import { RoadMapSummaryData } from '@/datamodel/types'
import { Options, Vue } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { RoadMapData } from '../roadmap/data-model'
import RoadMapView from '@/components/roadmap/index.vue'
import DPredictionSumaryView from '@/components/desk/prediction/d-prediction-sumary-view.vue'
import MultiBetView from '@/components/multi-view/multi-bet-view.vue'
import MultiCardResult from '@/components/multi-view/multi-card-result.vue'
import { Action } from 'vuex-class'
import MultiPrediction from './multi-prediction.vue'
import { useI18n } from 'vue-i18n'

@Options({
    components: {
        RoadMapView,
        DPredictionSumaryView,
        MultiBetView,
        MultiCardResult,
        MultiPrediction
    }
})
export default class MultiViewSideListItem extends Vue {
    iN = useI18n().t
    @Prop({ default: -1 })
    index!: number

    @Prop({ default: undefined })
    deskData!: DeskData

    @Prop({ default: -1 })
    chipAmount!: number

    roadmapData!: RoadMapData

    @Prop({ default: false }) isDragActive!: boolean
    @Action('setCurrentDesk')
    setCurrentDesk!: (model: DeskData | undefined) => void

    isCurrentlyOnDraw = false

    nextRedRoadMapSummaryData: RoadMapSummaryData = {}
    nextBlueRoadMapSummaryData: RoadMapSummaryData = {}
    nextRed!: RoadMapData
    nextBlue!: RoadMapData

    allowBet = false

    predictionTimer: number | boolean = false
    predictionType = 0
    predictionCount = 9
    predictionBlink = 9

    currentTime = -1
    deskTimer: number | boolean = false
    currentGameId: string | undefined
    showResult = false
    currentGameResult: GameResult | undefined

    deskResult = ''

    beforeUnmount(): void {
        if (typeof this.predictionTimer === 'number') {
            clearInterval(this.predictionTimer)
            this.predictionTimer = false
        }

        this.StopDeskTimer()
    }

    StopDeskTimer(): void {
        if (typeof this.deskTimer === 'number') {
            this.allowBet = false
            this.resetBetView()
            clearInterval(this.deskTimer)
            this.deskTimer = false
            this.currentTime = -1
        }
    }

    OnRoadMapData(
        data: RoadMapData,
        nextBlue: RoadMapData,
        nextRed: RoadMapData
    ): void {
        this.nextRedRoadMapSummaryData = { data: nextRed }
        this.nextBlueRoadMapSummaryData = { data: nextBlue }
    }

    handleOnPredict(value: number): void {
        const roadMapView = this.$refs['roadmapview'] as RoadMapView
        roadMapView.Predict(value)
    }

    resetBetView(): void {
        const betview = this.$refs['betview'] as MultiBetView
        if (betview) betview.resetBet()
    }

    OnClickVideo(): void {
        this.$emit('switch-focus-desk', this.deskData.deskId)
    }

    enterTable(): void {
        this.setCurrentDesk(this.deskData)
        this.$router.push({ name: 'game' })
    }

    //#region Watcher
    @Watch('isDragActive')
    OnEnterDrag(value: boolean, oldValue: boolean): void {
        if (oldValue !== value) {
            const mapView = this.$refs['roadmapview'] as RoadMapView

            if (mapView) {
                mapView.isActive = value
                mapView.drapMap()
            }
        }
    }

    @Watch('deskData', { immediate: true })
    UpdateRoadMap(value: DeskData): void {
        if (value) {
            this.deskResult = value.result || ''
            if (value.oldwin) {
                if (this.currentGameId !== value.gameId) {
                    this.currentGameId = value.gameId
                    this.currentGameResult = new GameResult(
                        value.rawData,
                        this.gameType
                    )
                    this.showResult = true
                    setTimeout(() => {
                        this.showResult = false
                        this.currentGameResult = undefined
                    }, 4000)
                }
            }

            if (value.currentTime > -1 && typeof this.deskTimer !== 'number') {
                this.allowBet = true
                this.currentTime = value.currentTime
                this.deskTimer = setInterval(() => {
                    if (this.currentTime !== -1) {
                        this.currentTime -= 1
                    } else {
                        this.StopDeskTimer()
                    }
                }, 1000)
            } else if (
                value.currentTime === -1 &&
                typeof this.deskTimer === 'number'
            ) {
                this.StopDeskTimer()
            }
        }
    }
    //#endregion

    //#region  Computed Properties
    get gameType(): number {
        if (!this.deskData) return 2
        else return this.deskData.gameType || 2
    }

    get title(): string {
        if (this.deskData.gameType === 1) {
            return `百家乐 ${this.deskData.deskId} 号桌`
        } else {
            return `龙虎 ${this.deskData.deskId} 号桌`
        }
    }

    get timerClass(): string {
        if (this.currentTime > 20) {
            return 'green'
        } else if (this.currentTime <= 20 && this.currentTime > 10) {
            return 'orange'
        } else {
            return 'red'
        }
    }
    //#endregion
}
