
import { Options } from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'
import { CardResultMixin } from '../desk/card-result/cardresultmixin'

@Options({})
export default class MultiCardResult extends Mixins(CardResultMixin) {
    get winLabel(): string {
        if (!this.gameResult) return ''
        if (this.gameResult.whoWin <= 0 || this.gameResult.whoWin > 3) return ''

        if (this.gameType === 1) {
            if (this.gameResult.whoWin === 1) return '庄赢'
            else if (this.gameResult.whoWin === 2) return '和局'
            else if (this.gameResult.whoWin === 3) return '闲赢'
        } else if (this.gameType === 2) {
            if (this.gameResult.whoWin === 1) return '龙赢'
            else if (this.gameResult.whoWin === 2) return '和局'
            else if (this.gameResult.whoWin === 3) return '虎赢'
        }

        return ''
    }
}
