<template>
    <div class="multi-baccarat-bet-view-root">
        <div class="row">
            <multi-bet-area
                class="bet-area"
                :betType="1"
                :offSet="1"
                :chipValue="chipValue"
                :currentIndex="selectedBetArea"
                :tempAmount="blueTemp"
                :allowBet="allowBet"
                :confirmedAMount="blueFinal"
                v-on:place-bet="OnPlaceBet"
                v-on:cancel-bet="OnCancelBetTemp"
                v-on:on-confirm-bet="OnConfirmBet"
                :showFlash="isBlue"
            />
            <multi-bet-area
                class="bet-area"
                :betType="2"
                :offSet="1"
                :chipValue="chipValue"
                :currentIndex="selectedBetArea"
                :tempAmount="greenTemp"
                :allowBet="allowBet"
                :confirmedAMount="greenFinal"
                v-on:place-bet="OnPlaceBet"
                v-on:cancel-bet="OnCancelBetTemp"
                v-on:on-confirm-bet="OnConfirmBet"
                :showFlash="isGreen"
            />
            <multi-bet-area
                class="bet-area"
                :betType="s6Mode ? 8 : 0"
                :offSet="1"
                :chipValue="chipValue"
                :tempAmount="s6Mode ? redSuper6Temp : redTemp"
                :confirmedAMount="redFinal"
                :currentIndex="selectedBetArea"
                :allowBet="allowBet"
                v-on:place-bet="OnPlaceBet"
                v-on:cancel-bet="OnCancelBetTemp"
                v-on:on-confirm-bet="OnConfirmBet"
                :showFlash="isRed"
            />
        </div>
        <div class="row">
            <multi-bet-area
                class="bet-area"
                :betType="6"
                :offSet="1"
                :chipValue="chipValue"
                :currentIndex="selectedBetArea"
                :tempAmount="bluePairTemp"
                :confirmedAMount="bluePairFinal"
                :allowBet="allowBet"
                v-on:place-bet="OnPlaceBet"
                v-on:cancel-bet="OnCancelBetTemp"
                v-on:on-confirm-bet="OnConfirmBet"
                :showFlash="isBluePair"
            />
            <multi-bet-area
                class="bet-area"
                :betType="7"
                :offSet="1"
                :chipValue="chipValue"
                :currentIndex="selectedBetArea"
                :tempAmount="redPairTemp"
                :confirmedAMount="redPairFinal"
                :allowBet="allowBet"
                v-on:place-bet="OnPlaceBet"
                v-on:cancel-bet="OnCancelBetTemp"
                v-on:on-confirm-bet="OnConfirmBet"
                :showFlash="isRedPair"
            />
        </div>
        <div class="pop-message" v-if="showMessage">
            <span>{{ message }}</span>
        </div>
    </div>
</template>

<script>
import { Mixins } from 'vue-property-decorator'
import { BaccaratBetViewMixin } from '@/components/desk/betview/baccarat-bet-view-mixin'
import MultiBetArea from './multi-bet-area.vue'
import { Options } from 'vue-class-component'

@Options({
    components: {
        MultiBetArea
    }
})
export default class MultiBaccaratBetView extends Mixins(
    BaccaratBetViewMixin
) {}
</script>

<style lang="scss" scoped>
.multi-baccarat-bet-view-root {
    display: flex;
    flex-direction: column;
    border: solid 0.0625rem rgba(255, 230, 166, 0.2);
    position: relative;
    .row {
        display: flex;
        flex-direction: row;
        height: 100%;
        width: 100%;
        border-top: solid 0.0625rem rgba(255, 230, 166, 0.2);
        &:first-child {
            border-top: none;
        }
        .bet-area {
            width: 100%;
            height: 100%;
            &:not(:first-child) {
                border-left: solid 0.0625rem rgba(255, 230, 166, 0.2);
            }
        }
    }
    .pop-message {
        pointer-events: none;
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 4;
        span {
            display: block;
            color: #fff;
            font-size: 1.3rem;
            background-color: #15130e;
            border: solid 0.0625rem rgba(151, 151, 151, 0.3);
            width: 10rem;
            height: 4rem;
            text-align: center;
            line-height: 4rem;
            border-radius: 0.3125rem;
        }
    }
}
</style>
