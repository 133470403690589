<template>
    <div class="multi-dragon-tiger-bet-view-root">
        <multi-bet-area
            class="bet-area"
            :betType="5"
            :tempAmount="redTemp"
            :confirmedAMount="redFinal"
            :currentIndex="selectedBetArea"
            :allowBet="allowBet"
            v-on:place-bet="OnPlaceBet"
            v-on:cancel-bet="OnCancelBetTemp"
            v-on:on-confirm-bet="OnConfirmBet"
            :chipValue="chipValue"
            :showFlash="isRed"
        />
        <multi-bet-area
            class="bet-area"
            :betType="3"
            :tempAmount="greenTemp"
            :confirmedAMount="greenFinal"
            :currentIndex="selectedBetArea"
            :allowBet="allowBet"
            v-on:place-bet="OnPlaceBet"
            v-on:cancel-bet="OnCancelBetTemp"
            v-on:on-confirm-bet="OnConfirmBet"
            :chipValue="chipValue"
            :showFlash="isGreen"
        />

        <multi-bet-area
            class="bet-area"
            :betType="4"
            :tempAmount="blueTemp"
            :confirmedAMount="blueFinal"
            :currentIndex="selectedBetArea"
            :allowBet="allowBet"
            v-on:place-bet="OnPlaceBet"
            v-on:cancel-bet="OnCancelBetTemp"
            v-on:on-confirm-bet="OnConfirmBet"
            :chipValue="chipValue"
            :showFlash="isBlue"
        />
        <div class="pop-message" v-if="showMessage">
            <span>{{ message }}</span>
        </div>
    </div>
</template>

<script>
import { Mixins } from 'vue-property-decorator'
import MultiBetArea from './multi-bet-area.vue'
import { Options } from 'vue-class-component'
import { DragonTigerBetViewMixin } from '@/components/desk/betview/dragon-tiger-bet-view-mixin'

@Options({
    components: {
        MultiBetArea
    }
})
export default class MultiDragonTigerBetView extends Mixins(
    DragonTigerBetViewMixin
) {}
</script>

<style lang="scss" scoped>
.multi-dragon-tiger-bet-view-root {
    display: flex;
    flex-direction: row;
    border: solid 0.0625rem rgba(255, 230, 166, 0.2);
    position: relative;
    .bet-area {
        width: 100%;
        height: 100%;
        &:not(:first-child) {
            border-left: solid 0.0625rem rgba(255, 230, 166, 0.2);
        }
    }

    .pop-message {
        pointer-events: none;
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 4;
        span {
            display: block;
            color: #fff;
            font-size: 1.3rem;
            background-color: #15130e;
            border: solid 0.0625rem rgba(151, 151, 151, 0.3);
            width: 10rem;
            height: 4rem;
            text-align: center;
            line-height: 4rem;
            border-radius: 0.3125rem;
        }
    }
}
</style>